@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap');
@import '~antd/dist/antd.css';
@import 'assets/icons.scss';
body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.loading-container {
  background: #fff;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 10;
}