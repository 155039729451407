@font-face {
  font-family: 'icons';
  src: url('fonts/apg-wildrift.eot?lqe3ea');
  src: url('fonts/apg-wildrift.eot?lqe3ea#iefix') format('embedded-opentype'), url('fonts/apg-wildrift.ttf?lqe3ea') format('truetype'), url('fonts/apg-wildrift.woff?lqe3ea') format('woff'), url('fonts/apg-wildrift.svg?lqe3ea#apg-wildrift') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-wild-rift-logo:before {
  content: "\e91c";
  color: #4ac5f2;
}

.icon-icon-baron-lane:before {
  content: "\e91a";
  color: #fff;
}

.icon-icon-dragon-lane:before {
  content: "\e91b";
  color: #fff;
}

.icon-icon-assistants:before {
  content: "\e918";
  color: #fff;
}

.icon-icon-deaths:before {
  content: "\e919";
  color: #fff;
}

.icon-icon-baron:before {
  content: "\e900";
  color: #fff;
}

.icon-icon-bottom-lane .path1:before {
  content: "\e901";
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.icon-icon-bottom-lane .path2:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-bottom-lane .path3:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.icon-icon-elder-infernal-drake:before {
  content: "\e904";
  color: #e86b26;
}

.icon-icon-elder-mountain-drake:before {
  content: "\e905";
  color: #a77f5d;
}

.icon-icon-elder-ocean-drake:before {
  content: "\e906";
  color: #a7c9e0;
}

.icon-icon-elder-wind-drake:before {
  content: "\e907";
  color: #67c0ac;
}

.icon-icon-first-blood:before {
  content: "\e908";
  color: #fff;
}

.icon-icon-herald:before {
  content: "\e909";
  color: #fff;
}

.icon-icon-infernal-drake:before {
  content: "\e90a";
  color: #e86b26;
}

.icon-icon-jungle:before {
  content: "\e90b";
  color: #fff;
}

.icon-icon-kills:before {
  content: "\e90c";
  color: #fff;
}

.icon-icon-mid-lane .path1:before {
  content: "\e90d";
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.icon-icon-mid-lane .path2:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.icon-icon-mid-lane .path3:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-mountain-drake:before {
  content: "\e910";
  color: #a77f5d;
}

.icon-icon-ocean-drake:before {
  content: "\e911";
  color: #a7c9e0;
}

.icon-icon-support:before {
  content: "\e912";
  color: #fff;
}

.icon-icon-top-lane .path1:before {
  content: "\e913";
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.icon-icon-top-lane .path2:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-top-lane .path3:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.icon-icon-tourrete:before {
  content: "\e916";
  color: #fff;
}

.icon-icon-wind-drake:before {
  content: "\e917";
  color: #67c0ac;
}